
import * as Sentry from '@sentry/vue'
import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator'
import AddBIS from '@/components/modals/add_bis.vue'
import BISList from '@/interfaces/bis_list'
import { Character, CharacterDetails } from '@/interfaces/character'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component
export default class TeamMemberForm extends SavageAimMixin {
  @Prop({ default: '-1' })
  initialBisListId!: string

  @Prop({ default: '-1' })
  initialCharacterId!: string

  @Prop({ default: undefined })
  bisListIdErrors!: string[] | undefined

  @Prop({ default: undefined })
  characterIdErrors!: string[] | undefined

  bisListsLoaded = false

  bisLists: BISList[] = []

  bisListId = this.initialBisListId

  characterId = this.initialCharacterId

  refreshNote = false

  get bisIcon(): string {
    const list = this.bisLists.find((bl: BISList) => bl.id === parseInt(this.bisListId, 10))
    if (list != null) {
      return list.job.id
    }
    return ''
  }

  get characters(): Character[] {
    return this.$store.state.characters
  }

  get chosen(): Character | undefined {
    return this.characters.find((char: Character) => char.id === parseInt(this.characterId, 10))
  }

  addBIS(): void {
    this.$modal.show(AddBIS, { character: this.chosen }, { }, { closed: () => this.fetchBis() })
  }

  characterUrl(id: string): string {
    return `/backend/api/character/${id}/`
  }

  @Watch('characterId')
  async fetchBis(): Promise<void> {
    // Whenever the character id is changed we should fetch the BIS Lists
    this.bisListsLoaded = false

    // If not, go fetch the bis lists for the character
    const url = this.characterUrl(this.characterId)
    try {
      const response = await fetch(url)
      if (response.ok) {
        // Parse the list into an array of character interfaces and store them in the character data list
        const details = (await response.json()) as CharacterDetails
        this.bisLists = details.bis_lists
        this.bisListsLoaded = true
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching BIS Lists for the chosen Character.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  mounted(): void {
    if (this.initialCharacterId !== '-1') this.fetchBis()
  }
}
