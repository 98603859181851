
import { Component, Prop, Vue } from 'vue-property-decorator'
import BISListForm from '@/components/bis_list/form.vue'
import BISListModify from '@/dataclasses/bis_list_modify'
import { CharacterDetails } from '@/interfaces/character'
import { BISListErrors } from '@/interfaces/responses'

@Component({
  components: {
    BISListForm,
  },
})
export default class AddBIS extends Vue {
  bisList = new BISListModify()

  @Prop()
  character!: CharacterDetails

  errors: BISListErrors = {}

  // Url to send data to
  get url(): string {
    return `/backend/api/character/${this.character.id}/bis_lists/`
  }

  handleError(errorCode: number): void {
    if (errorCode === 400) return
    this.$notify({ text: `Something went wrong; HTTP ${errorCode}. Try adding a new BIS from the standard page instead.`, type: 'is-danger' })
  }
}
